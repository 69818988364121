import { Injectable, OnDestroy } from '@angular/core';
import { LogService } from '../services/loggers/logger.service';

interface MemoryPerformance extends Performance {
  memory?: {
    usedJSHeapSize: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class MemoryUsageService implements OnDestroy {
  private performanceLog: number | undefined;

  constructor(private log: LogService) { }

  ngOnDestroy() {
    clearInterval(this.performanceLog);
  }

  async logMemoryUsagePeriodically(interval: number) {
    // check first if MemoryPerformance is available
    if (window.performance && (window.performance as MemoryPerformance).memory) {
      setInterval(() => {
        const memory = (window.performance as MemoryPerformance).memory;
        if (memory) {
          this.log.info(`Memory usage: ${memory.usedJSHeapSize / 1000000} MB`);
        }
      }, interval);
    } else { // else resort to Performance api
      const performance = window.performance as Performance & { measureUserAgentSpecificMemory?: () => Promise<{ bytes: number }> };
      if (!window.crossOriginIsolated) {
        this.log.info('performance.measureUserAgentSpecificMemory() to log memory usage is only available in cross-origin-isolated pages');
      } else if (!performance.measureUserAgentSpecificMemory) {
        this.log.info('performance.measureUserAgentSpecificMemory() to log memory usage is not available in this browser');
      } else {
        try {
          await performance.measureUserAgentSpecificMemory();
          // log memory usage to backend log with defined interval
          this.performanceLog = window.setInterval(() => void (async () => {
            if (performance.measureUserAgentSpecificMemory) {
              const result = await performance.measureUserAgentSpecificMemory();
              this.log.info(`Memory usage information: ${(result as unknown as { bytes: number }).bytes / 1_000_000} MB`);
            }
          })(), interval);
        } catch (error) {
          if (error instanceof DOMException && error.name === 'SecurityError') {
            this.log.info('The context is not secure to log memory usage.');
            clearInterval(this.performanceLog);
          } else {
            throw error;
          }
        }
      }
    }
  }
}
