import { Overlay } from '@angular/cdk/overlay';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/legacy-autocomplete';
import { MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/legacy-checkbox';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS, MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/legacy-radio';
import { MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG } from '@angular/material/legacy-select';
import { MAT_LEGACY_SLIDE_TOGGLE_DEFAULT_OPTIONS as MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/legacy-slide-toggle';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VIRTUAL_SCROLLER_DEFAULT_OPTIONS_FACTORY } from '@iharbeck/ngx-virtual-scroller';
import { IonicModule } from '@ionic/angular';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { FlatpickrModule } from 'angularx-flatpickr';
import flatpickr from 'flatpickr';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LoggerModule, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackendUnavailabeDialogComponent } from './components/dialogs/backend-unavailabe-dialog/backend-unavailabe-dialog.component';
import { ErrorAlertComponent } from './components/error-alert-modal/error-alert.component';
import { MenuBarModule } from './components/menu-bar/menu-bar.module';
import {
  SnackbarDefaultContentContainerModule
} from './components/snackbar-default-content-container/snackbar-default-content-container.module';
import { MaterialImportsModule } from './material-imports.module';
import { applyPatch as applyCtrlClickPatch } from './monkey/router-link-delegate';
import { GlobalErrorHandlerService } from './services/error/global-error-handler/global-error-handler.service';
import { CustomNGXLoggerHttpService } from './services/loggers/implementation/custom-ngx-logger-http.service';
import { NgxTranslateModule } from './translate.module';
import { initializeKeycloak } from './utility/keycloak';

// Global tooltip options
const globalTooltipOptions: MatTooltipDefaultOptions = {
  hideDelay: 0,
  showDelay: 0,
  touchendHideDelay: 1500,
  position: 'above'
};

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always'
};

function matAutoCompleteCustomScrollStrategyFactory(overlay: Overlay) {
  return () => overlay.scrollStrategies.reposition();
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorAlertComponent,
    BackendUnavailabeDialogComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialImportsModule,
    MenuBarModule,
    LoggerModule.forRoot(environment.ngxLoggerConfig.logging, {
      serverProvider: {
        provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: CustomNGXLoggerHttpService
      }
    }),
    IonicModule.forRoot(),
    KeycloakAngularModule,
    SnackbarDefaultContentContainerModule,
    FlatpickrModule.forRoot(),
    NgxTranslateModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: globalTooltipOptions },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: matAutoCompleteCustomScrollStrategyFactory,
      deps: [Overlay]
    },
    {
      provide: 'virtual-scroller-default-options',
      useFactory: VIRTUAL_SCROLLER_DEFAULT_OPTIONS_FACTORY
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideIonicAngular(),
  ] })
export class AppModule {
  constructor() {
    flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday
  }
}

applyCtrlClickPatch();
