import { HttpRequest } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { DefaultConfig } from 'src/assets/default.config';

export function initializeKeycloak(
  keycloak: KeycloakService
) {
  let checkLoginIframe = true;
  if (window.location.hostname !== (new URL(DefaultConfig.keycloak.url)).hostname) {
    //console.debug('Auth server and client hosts differ');
    checkLoginIframe = false;
  }

  return () =>
    keycloak.init({
      config: DefaultConfig.keycloak,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe
      },
      // only add the token to requests to our API backend
      shouldAddToken: (req: HttpRequest<unknown>): boolean => {
        const { url } = req;
        const isAcceptablePath = url.startsWith(DefaultConfig.baseApi);
        return isAcceptablePath;
      }
    });
}

// Easy fix for the service not having
// a Keycloak instance initialized,
// which otherwise would result in a
// access-on-undefined-error
export const KeycloakServiceMock = {
  isUserInRole: (_: any) => true,
  login: (_: any) => true,
  isLoggedIn: (_: any) => true,
  logout: (_: any) => true,
  getToken: (_: any) => '',
  updateToken: (_: any) => Promise.resolve(),
  init: (_: any) => true,
  loadUserProfile: (_: any) => new Promise(() => undefined),
  getKeycloakInstance: (_: any) => ({ subject: 'xy' }),
  keycloakEvents$: new Subject(),
};

@NgModule({
  declarations: [],
  providers: [
    {
      provide: KeycloakService,
      useValue: KeycloakServiceMock
    }
  ]
})
export class KeycloakMockModule { }
