import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { DASHBOARD_PAGE_MODE_STORAGE_KEY, DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY, getDashboardSetting } from './dashboard-helper';

export function DashboardGuard(): CanActivateFn {
  return () => {
    const oauthService: AuthService = inject(AuthService);
    const router: Router = inject(Router);

    void oauthService.getCurrentUserId().then((currentUserID) => {
      let dashboardPageMode: string = getDashboardSetting(currentUserID, DASHBOARD_PAGE_MODE_STORAGE_KEY) || 'thingies';
      if (dashboardPageMode !== 'devices') {
        dashboardPageMode = getDashboardSetting(currentUserID, DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY) || 'cards';
      }

      return router.navigate([`dashboard/${dashboardPageMode}`]);
    });

    return false;
  };
}
