import { Component, NgModule } from '@angular/core';
import { RouteReuseStrategy, Router, RouterModule, Routes } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { DeviceAddressRedirectionGuard } from './guards/device-address-redirection/device-address-redirection.guard';
import { DeviceDetailDialogOpenGuard } from './guards/device-detail-dialog-open/device-detail-dialog-open.guard';
import { TeamDetailGuard } from './guards/team-detail/team-detail.guard';
import { TeamManagementAccessGuard } from './guards/team-management-page/team-management-access.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TeamsResolverService } from './services/resolvers/team-list/team-list-resolver.service';
import { UserListResolverService } from './services/resolvers/user-list/user-list-resolver.service';
import { AuthGuard } from './utility/authGuard';
import { DashboardGuard } from './utility/dashboard-guard';

const routes: Routes = [
  {
    // have a dummy route which is parent to all other routes with auth enabled
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'thingies',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
          }, {
            path: 'thingie/:id',
            loadChildren: () => import('./pages/thingie-detail/thingie-detail/thingie-detail.module').then(m => m.ThingieDetailModule)
          },
          {
            path: 'create',
            loadChildren: () => import('./pages/thingie-creation/thingie-creation.page.module').then(m => m.ThingieCreationModule)
          },
          {
            path: 'fakedata/:id',
            loadChildren: () => import('./pages/thingie-detail/fakedata/thingie-fakedata.page.module').then(m => m.ThingieFakeDataModule)
          },
        ]
      },
      {
        path: 'devices',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'device/:deviceid',
            canLoad: [DeviceDetailDialogOpenGuard],
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          // For special resolve-redirection-purposes
          // from a device address to the device page
          {
            path: 'deviceAddress/:address',
            canActivate: [DeviceAddressRedirectionGuard],
            // Has to be provided, because 'redirectTo' will ovrride our URLTree
            component: (class extends Component {})
          },
          {
            path: 'create',
            loadChildren: () => import('./pages/device-creation/device-creation.page.module').then(m => m.DeviceCreationModule)
          },
        ]
      },
      {
        path: 'devices-debug',
        children: [
          {
            path: 'device/:id',
            loadChildren: () => import('./pages/device-detail/device-detail.module').then(m => m.DeviceDetailModule)
          },
        ]
      },
      // {
      //   path: 'user-center',
      //   loadChildren: () => import('./pages/user-center/user-center.module').then(m => m.UserCenterModule)
      // },
      {
        path: 'vendor-tooling',
        loadChildren: () => import('./pages/vendor-tooling/vendor-tooling.module').then(m => m.VendorToolingModule)
      },
      {
        path: 'error/:code/:msg/:text',
        loadChildren: () => import('./pages/404/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
      },
      {
        path: 'swagger', redirectTo: 'swagger/', pathMatch: 'full'
      },
      {
        path: 'swagger/:service',
        loadChildren: () => import('./pages/swagger/swagger.module').then(m => m.SwaggerModule)
      },
      {
        path: 'dashboard/:id',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'admin/:id',
        // canLoad: [AdminAccessGuard],
        loadChildren: () => import('./pages/admin-page/admin-page.module').then(m => m.AdminPageModule)
      },
      {
        path: 'team-detail/:tab/:id',
        canActivate: [TeamDetailGuard],
        resolve: {
          userList: UserListResolverService
        },
        loadChildren: () => import('./pages/team-detail/team-detail.module').then(m => m.TeamDetailModule)
      },
      {
        path: 'team-management',
        canActivate: [TeamManagementAccessGuard],
        resolve: {
          teams: TeamsResolverService
        },
        loadChildren: () => import('./pages/team-management-page/team-management-page.module').then(m => m.TeamManagementPageModule)
      },
      {
        path: '',
        canActivate: [DashboardGuard()],
        component: DashboardComponent
      },
      {
        path: 'dots-calibration',
        loadChildren: () => import('./pages/dots-calibration/dots-calibration.module').then(m => m.DotsCalibrationModule)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/404/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ]
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.onSameUrlNavigation = 'reload';
  }
}
