import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeviceService } from 'src/app/services/device/device.service';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class DeviceAddressRedirectionGuard  {
  constructor(
    private deviceService: DeviceService,
    private router: Router
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    UNUSED_ARGS(state);

    return await this.buildRouteFromDeviceAddress(route.params.address);
  }

  private async buildRouteFromDeviceAddress(address: string): Promise<UrlTree> {
    const decoded = decodeURIComponent(address);

    const device = (await lastValueFrom(this.deviceService.getDevice(decoded)
      .pipe(take(1))))!;

    return this.router.parseUrl(`/devices/device/${device._id}`);
  }
}
