import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { TeamService } from 'src/app/services/team/team.service';
import { UserService } from 'src/app/services/user/user.service';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class TeamDetailGuard  {
  constructor(
    private auth: AuthService,
    private log: LogService,
    private userService: UserService,
    private teamService: TeamService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    UNUSED_ARGS(route, state);

    //// User is an admin?
    //// Skip other checks and allow navigation!


    try {
      if (await this.auth.isAdmin()) {
        this.log.debug(`${TeamDetailGuard.name}::canActivate(): access granted (admin)`);
        return true;
      }
    } catch (err: any) {
      this.log.error('Failed to determine if current user is an admin, proceeding with team checks', err.message);
    }

    //// Allow only if a non-admin is a leader of the team represented
    //// by the team id

    try {
      const user = await this.userService.getUserById({ userId: await this.auth.getCurrentUserId() });

      if (!route.params.id) {
        throw new Error(`Invalid route for ${TeamDetailGuard.name}`);
      }

      this.log.debug(`Will check access permissions for user '${user.username}'...'`);

      const { leaders } = await this.teamService.getUsersByTeamGroupedByRole({
        teamId: route.params.id
      });

      // Is user a leader?...
      if (leaders.find(leader => leader.username === user.username) !== undefined) {
        this.log.debug(`${TeamDetailGuard.name}::canActivate(): access granted (team leader)`);
        return true;
      }

      this.log.error(`${TeamDetailGuard.name}::canActivate(): access denied`);
      return false;
    } catch (err: any) {
      this.log.error('Failed to determine, if user is in team', err.message);
      return false;
    }
  }
}
