import { RouterLinkDelegate } from '@ionic/angular';

export function applyPatch() {
  RouterLinkDelegate.prototype.onClick = function($event: UIEvent) {
    const ionic = (window as any).Ionic;
    if (ionic && ionic.platforms.includes('desktop') && $event.type === 'click') {
      const mouseEvent = $event as MouseEvent;
      if (mouseEvent.button === 0 && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
        return;
      }
    }

    // @ts-ignore -- property is private
    this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
    $event.preventDefault();
  };

  console.warn('Patch for CTRL/Meta+Click applied.');
}
