import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { getPathFromRoot } from './utility/routing/route-path-from-root';
import { UNUSED_ARGS } from './utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  dashboardTree?: DetachedRouteHandle;

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return true;
    }

    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    if (!route.component) {
      return;
    }

    // force to remove the tooltip on route change
    while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
      document.getElementsByTagName('mat-tooltip-component')[0].remove();
    }

    if (this.getPathFromRoot(route).includes('/dashboard/cards')) {
      this.dashboardTree = detachedTree;

      // The jsdoc of the store function mentions that storing `null` means erasing the previously
      // stored value. As we use `undefined` to indicate a missing detached route, set to `undefined`.
      if (detachedTree === null) {
        this.dashboardTree = undefined;
      }
    }
    UNUSED_ARGS(route, detachedTree);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!route.component) {
      return false;
    }
    if (this.dashboardTree !== undefined && this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return true;
    }

    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.component) {
      return null;
    }
    if (this.dashboardTree !== undefined && this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return this.dashboardTree;
    }

    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futurePath = this.getPathFromRoot(future);
    const currPath   = this.getPathFromRoot(curr);

    if (futurePath.includes('/thingies/create')) {
      // Here we don't re-use the route to reset the wizard if we are already in it
      return false;
    }
    if (currPath.includes('/dashboard/devices') && futurePath.includes('/devices/')) {
      return true;
    }
    if (currPath.includes('/devices/device') && futurePath.includes('/devices/device')) {
      return true;
    }
    if (currPath.includes('/devices/') && futurePath.includes('/dashboard/devices')) {
      return true;
    }
    if (currPath.includes('/dashboard/cards') && futurePath.includes('/dashboard/cards/stack')) {
      return true;
    }
    if (currPath.includes('/dashboard/cards/stack') && futurePath.includes('/dashboard/cards')) {
      return true;
    }
    // if(currPath.includes('/thingies/create') && futurePath.includes('/dashboard/cards/stack')) {
    //   return true;
    // }

    return future.routeConfig === curr.routeConfig;
  }

  getPathFromRoot(route: ActivatedRouteSnapshot) {
    return getPathFromRoot(route);
  }
}
