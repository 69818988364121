import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class TeamManagementAccessGuard  {
  constructor(
    private auth: AuthService,
    private log: LogService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    UNUSED_ARGS(route, state);

    //// Allow access only if current user is a leader of the team represented
    //// by the team id

    try {
      // Admin?
      // - please continue!
      if (await this.auth.isAdmin()) {
        this.log.debug(`Team management: access granted to admin`);
        return true;
      }


      //// Check non-admin users for the leader attribute

      this.log.debug(`Will check access permissions for user...'`);

      // Is user a leader?...
      if (await this.auth.isTeamLeader()) {
        this.log.debug(`Team management: access granted`);
        return true;
      }

      this.log.error(`Team management: access denied`);
      return false;
    } catch (err: any) {
      this.log.error('Failed to determine, if user is in team', err.message);
      return false;
    }
  }
}
