import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { from } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { LogService } from '../../loggers/logger.service';
import { UINotificationService } from '../../ui-notification/uinotification.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler implements OnDestroy {
  private subsink = new SubSink();

  constructor(
    private log: LogService,
    private uiNotifications: UINotificationService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  handleError(error: any) {
    this.subsink.sink = from(this.uiNotifications.parseErrorToLogMessage(error))
      .pipe(
        distinctUntilChanged((el1, el2) => el1.logMessage === el2.logMessage)
      )
      .subscribe(({ logMessage, error }) => this.log.error(`Uncaught exception - ${logMessage}`, error));

    if (environment.production !== true) {
      this.uiNotifications.displayErrorAlert({
        title: 'Unhandled Error',
        message: 'An uncaught exception occurred (see details for more info)',
        details: undefined
      },
      error);
    }
  }
}
