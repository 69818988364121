import { Component, OnInit } from '@angular/core';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { UserAppSettingsService } from './services/app-settings/user-app-settings.service';
import { AuthService } from './services/auth/auth.service';
import { BackendAvailabilityService } from './services/backend-availability/backend-availability.service';
import { LogService } from './services/loggers/logger.service';
import { MemoryUsageService } from './services/memory-logger';
import { UNUSED_ARGS } from './utility/unused-arguments';

export function addUsedIcons() {
  const usedIcons: (keyof typeof icons)[] = [
    'add',
    'addCircleOutline',
    'addOutline',
    'alert',
    'alertCircleOutline',
    'alertOutline',
    'analyticsOutline',
    'archiveOutline',
    'arrowBackOutline',
    'arrowForwardOutline',
    'arrowUpOutline',
    'banOutline',
    'batteryChargingOutline',
    'batteryDeadOutline',
    'batteryFullOutline',
    'batteryHalfOutline',
    'bookmark',
    'bookmarkOutline',
    'calendarOutline',
    'calendarClearOutline',
    'caretDownOutline',
    'caretForwardOutline',
    'cellularOutline',
    'checkmark',
    'checkmarkCircle',
    'checkmarkCircleOutline',
    'checkmarkDoneOutline',
    'checkmarkOutline',
    'chevronBackOutline',
    'chevronDown',
    'chevronDownOutline',
    'chevronForwardOutline',
    'chevronUpOutline',
    'closeCircleOutline',
    'closeOutline',
    'cogOutline',
    'contractOutline',
    'copy',
    'copyOutline',
    'desktopOutline',
    'documentLockOutline',
    'documentTextOutline',
    'downloadOutline',
    'ellipsisVertical',
    'ellipsisVerticalOutline',
    'exitOutline',
    'expandOutline',
    'eyeOffOutline',
    'eyeOutline',
    'information',
    'fileTrayFullOutline',
    'fileTrayOutline',
    'fileTrayStackedOutline',
    'flashOutline',
    'flaskOutline',
    'folderOutline',
    'happyOutline',
    'helpCircleOutline',
    'helpOutline',
    'informationCircle',
    'informationCircleOutline',
    'link',
    'linkOutline',
    'lockClosed',
    'lockClosedOutline',
    'lockOpenOutline',
    'menuOutline',
    'notificationsOutline',
    'openOutline',
    'pencilOutline',
    'peopleOutline',
    'person',
    'personOutline',
    'pricetagOutline',
    'playOutline',
    'pauseOutline',
    'stopOutline',
    'prismOutline',
    'refreshOutline',
    'removeCircleOutline',
    'removeOutline',
    'reorderTwoOutline',
    'saveOutline',
    'search',
    'searchOutline',
    'sendOutline',
    'settingsOutline',
    'timeOutline',
    'todayOutline',
    'trashBinOutline',
    'trashOutline',
    'unlink',
    'unlinkOutline',
    'warningOutline',
  ];
  addIcons(Object.fromEntries(usedIcons.map(iconName => [iconName, icons[iconName]])));
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private log: LogService,

    /**
     * Injected so the settings will be requested asap
     */
    appSettings: UserAppSettingsService,
    backendAvailability: BackendAvailabilityService,
    private memoryUsageService: MemoryUsageService,
    private auth: AuthService
  ) {
    UNUSED_ARGS(appSettings);

    backendAvailability.startChecking();
    log.info('Browser Data: ' + navigator.userAgent);

    addUsedIcons();
  }

  ngOnInit() {
    // log memory usage to backend log with defined interval
    void this.memoryUsageService.logMemoryUsagePeriodically(300000); // 5 minutes = 300000 milliseconds
  }
}
