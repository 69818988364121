import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * For a given route, returns the path from the root of the route tree
 * as a string, separating each segment with a forward slash.
 *
 * @param route
 * @returns
 */
export function getPathFromRoot(route: ActivatedRouteSnapshot) {
  const root = route.root;

  let path = '';
  let current = root;

  while (current.children.length > 0) {
    const children = current.children;
    const next = children[0];
    path += '/' + next.url.map(segment => segment.path).join('/');
    current = next;
  }

  return path;
}
