import { lastValueFrom } from 'rxjs';

import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INGXLoggerConfig, INGXLoggerMetadata, INGXLoggerServerService, NgxLoggerLevel } from 'ngx-logger';
import { noop, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class CustomNGXLoggerHttpService implements INGXLoggerServerService {
  constructor(private readonly httpClient: HttpClient) { }

  // to resolve the breaking change in a patch release of ngx-logger package:
  // https://github.com/dbfannin/ngx-logger/blob/master/CHANGELOG.md
  // adding flushQueue as a noop
  flushQueue(): void {
    noop();
  }

  sendToServer(log: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    const url = config.serverLoggingUrl;
    if (url === undefined) {
      return;
    }
    // send the string loglevel
    const data = {
      ...log,
      level: NgxLoggerLevel[log.level]
    };

    // Comment from the original implementation
    //   HttpBackend skips all HttpInterceptors
    //   They may log errors using this service causing circular calls
    // But we need the interceptor for keycloak integration.
    const req = new HttpRequest<any>('POST', url, data, {});
    void lastValueFrom(this.httpClient.request(req).pipe(
      filter(e => e instanceof HttpResponse),
      map(e => e as HttpResponse<any>),
      map<HttpResponse<any>, any>((httpResponse: HttpResponse<any>) => httpResponse.body),
      catchError(() => of())
    ));
  }
}
